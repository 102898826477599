import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { AUTH_TOKEN, SIGNIN, SIGNOUT } from '../constants/Auth';
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
  setDashBoardNavTree
} from '../actions/Auth';

import authAdminService from 'services/auth/admin';
import { notification } from 'antd';
import authSubAdminService from 'services/auth/subAdmin';
import navigationConfig from 'configs/NavigationConfig';
import Utils from 'utils';
import sellerService from 'services/auth/seller';

export function* signIn() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { email, password } = payload;
    try {
      // const user = yield call(
      //   FirebaseService.signInEmailRequest,
      //   email,
      //   password
      // )

      const user = yield call(authAdminService.login, { authinfo: email, password });

      if (user?.sessionToken) {
        notification.success({ message: 'Login success' });

        window.localStorage.setItem(AUTH_TOKEN, user.sessionToken);

        if (user.user?.type === 'ADMIN') {
          // const getAdminProfile = yield call(authAdminService.getProfile);
          window.localStorage.setItem('auth_type', user.user?.type);
          window.localStorage.setItem('user_data', JSON.stringify(user.user));

          yield put(authenticated({ user: user.user }));
          // yield put(authenticated({ user: getAdminProfile }));
          yield put(setDashBoardNavTree(navigationConfig));
        }
      } else if (user?.user?.type !== "ADMIN") {
        notification.error({ message: 'User Type Not Supported' });

      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem('auth_type');
      localStorage.removeItem('user_data');
      yield put(signOutSuccess());
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(signIn), fork(signOut)]);
}
